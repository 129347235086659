<template>
  <div class="container-fluid articles" v-show="articles.length > 0">
    <div class="row">
      <div class="col-12 col-lg-9">
        <FeaturedArticle />
      </div>
      <div class="col-12 col-lg-3">
        <h2>RECENT ARTICLES</h2>
        <ul>
          <li v-for="(article, $idx) in articles" :key="$idx">
            <div class="title" v-html="sanitize(article.title)"></div>
            <div class="date-and-author">
              {{ fullDate(article.post_date) }} by:
              <a :href="article.author?.link">{{ authorName(article) }}</a>
            </div>
            <div><a :href="article.link">read more &raquo;</a></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const FeaturedArticle = () =>
  import("Components/VehicleSearch/FeaturedArticle");
export default {
  mounted() {
    this.$store.dispatch("fetchRecentArticles");
  },
  methods: {
    sanitize(text) {
      return this.$sanitize(text);
    },
    fullDate(date) {
      console.log(date);
      const d = new Date(date);
      return d.toLocaleString("default", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
    authorName(article) {
      return article.author?.name.split(" ")[0];
    },
  },
  computed: {
    articles() {
      return this.$store.state.recentArticles;
    },
  },
  components: {
    FeaturedArticle,
  },
};
</script>

<style lang="scss" scoped>
div.articles {
  background-color: $light-bg-color;
  padding-top: 10px;
  padding-bottom: 20px;

  h2 {
    margin-top: 21px;
    margin-bottom: 6px;
    font-family: $primary-font-family;
    font-size: 1.5rem;
    font-weight: 900;
    color: black;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    padding-bottom: 9px;
    .title {
      font-size: 0.925rem;
      color: black;
    }

    .date-and-author {
      color: $secondary-font-color-dark;
      font-size: 0.825rem;
      font-weight: 700;

      a {
        color: #3b9bf5;
        text-decoration: none;
        font-size: 0.825rem;
      }
    }
  }
}
</style>
